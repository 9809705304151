<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="充值卡号" name="cardNo" class="ui-form__item">
						<a-input v-model:value="formState.cardNo" placeholder="请输入充值卡号"></a-input>
					</a-form-item>

					<a-form-item label="充值用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入充值用户信息"></a-input>
					</a-form-item>

					<a-form-item label="充值订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入充值订单号"></a-input>
					</a-form-item>

					<a-form-item label="充值流水号" name="outTradeNo" class="ui-form__item">
						<a-input v-model:value="formState.outTradeNo" placeholder="请输入充值流水号"></a-input>
					</a-form-item>

					<!--          <a-form-item label="操作人" name="viewNum" class="ui-form__item">-->
					<!--            <a-input v-model:value="formState.viewNum" placeholder="请输入操作人"></a-input>-->
					<!--          </a-form-item>-->

					<a-form-item label="充值类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择充值类型" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">用户充值</a-select-option>
							<a-select-option :value="2">初始金额</a-select-option>
							<a-select-option :value="3">后台充值</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择状态" allow-clear
							style="width: 195px;">
							<!-- <a-select-option :value="0">待支付</a-select-option> -->
							<a-select-option :value="1">已完成</a-select-option>
							<a-select-option :value="2">退款中</a-select-option>
							<a-select-option :value="3">已退款</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="充值时间" class="ui-form__item">
						<a-range-picker v-model:value="payTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="售后时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="userUnionCardChargeOrderStrategy" :searchData="searchData"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 3300 }">
					<!-- <template #footer>
						累计充值金额：￥{{ total }}
					</template> -->
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.userNickname || '-' }}</div>
								<!-- <div>所属用户id：{{ record.userId }}</div> -->
								<div>用户手机号：{{ record.userPhone }}</div>
							</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'chargePriceBalance'">
							{{ record.chargePriceBalance + record.prizePriceBalance }}
						</template>
						<template v-if="column.key === 'accountPrice'">
							{{ record.chargePriceBalance + record.prizePriceBalance + record.chargePrice + record.prizePrice }}
						</template>
						<template v-if="column.key === 'fees'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment * 0.0054).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'predict'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'payMessageType'">
							<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
							<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
							<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
							<div v-else-if="record.payMessageType === 'zero'">0元支付</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'isCoupon'">
							{{ (record.userCouponList && record.userCouponList.length) ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 1">用户充值</div>
							<div v-if="record.type === 2">初始金额</div>
							<div v-if="record.type === 3">后台充值</div>
						</template>
						<template v-if="column.key === 'handleUserName'">
							{{ record.handleUserName || '-' }}
						</template>
						<template v-if="column.key === 'status'">
							{{ ['待支付', '已完成', '退款中', '已退款'][record.status] }}
						</template>
						<template v-if="column.key === 'afterInfo'">
							<div v-if="record.status == 3 || record.status == 2">
								<div>退款金额：{{ record.refundMessage?.refund || '-' }}</div>
								<div>售后时间：{{ transDateTime(record.auditTime) }}</div>
								<div>操作人：{{ record.refundMessage?.createUser || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="[1].includes(record.type) && record.status == 1" type="link" @click="onRefund(record)">售后</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<a-modal v-model:visible="showRefundModal" title="售后" width="650px" @ok="onRefundOk">
			<a-spin :spinning="loading">
				<a-form ref="refundFormRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
					<a-form-item label="退款金额" name="refundAmount" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.refundAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import exportReport from '@/components/exportReport/exportReport.vue';
	import { userUnionCardChargeOrderRefund } from '@/service/modules/coupon.js';
	import {
		getUserUnionCardChargeOrderList
	} from "@/service/modules/report";

	export default {
		components: { exportReport },
		data() {
			return {
				total: 0,
				loading: false,
				searchData: {},
				modelRef: {},
				payTime: [],
				time: [],
				formState: {},
				columns: [{
					title: '一卡通卡号',
					dataIndex: 'cardNo',
				}, {
					title: '用户信息', //（用户昵称、所属用户id、用户手机号）
					key: 'userInfo',
				}, {
					title: '充值时间',
					key: 'createTime'
				}, {
					title: '充值前余额',
					key: 'chargePriceBalance',
					width: 120
				}, {
					title: '充值金额',
					dataIndex: 'chargePrice',
					width: 120
				}, {
					title: '赠送金额',
					dataIndex: 'prizePrice',
					width: 120
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 140
				}, {
					title: '支付方式',
					key: 'payMessageType',
					width: 140
				},
				// {
				// 	title: '充值后金额',
				// 	key: 'accountPrice',
				// 	width: 130
				// },
				{
					title: '充值类型', //（用户充值、卡初始金额、后台充值）
					key: 'type',
					width: 150
				}, {
					title: '充值订单号',
					dataIndex: 'orderNo',
				}, {
					title: '充值流水号',
					dataIndex: 'outTradeNo',
				}, {
					title: '是否赠送券',
					key: 'isCoupon',
					width: 130
				}, {
					title: '操作人', //（展示后台用户，只有后台充值的类型才展示，其余展示为-）
					key: 'handleUserName',
					width: 150
				}, {
					title: '状态',
					key: 'status',
					width: 100
				}, {
					title: '售后信息',
					key: 'afterInfo',
				},
				// {
				// 	title: '售后状态',
				// 	key: '',
				// }, {
				// 	title: '售后时间',
				// 	key: '',
				// },
				{
					title: '操作',
					key: 'action',
					fixed: 'right',
					align: 'center',
					width: 100
				},
				],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showRefundModal: false,
				modelRef: {}
			}
		},
		created() {
			this.onSearch();
			// this.getTotal();
		},
		methods: {
			reset() {
				this.payTime = [];
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = this.$deepClone(this.formState);
				if (this.time && this.time.length) {
					this.searchData.startRefundTime = this.moment(this.time[0].startOf('day')).unix();
					this.searchData.endRefundTime = this.moment(this.time[1].endOf('day')).unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.payStartTime = this.moment(this.payTime[0].startOf('day')).unix();
					this.searchData.payEndTime = this.moment(this.payTime[1].endOf('day')).unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getUserUnionCardChargeOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getTotal() {
				this.loading = true;
				try {
					let ret = await getUserUnionCardChargeOrderList({})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.list.forEach(item => {
							this.total += item.chargePrice;
						})
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefundOk() {
				this.$refs.refundFormRef.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定售后退款吗？',
						onOk: async ()=> {
							this.loading = true;
							let ret = await userUnionCardChargeOrderRefund(this.modelRef);
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('售后成功！')
								this.showRefundModal = false;
								this.getData();
							}
						}
					})
				})
			},
			onRefund(item) {
				this.modelRef = {
					orderId: item.id
				}
				this.showRefundModal = true;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
